import { useState, useRef, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import PropTypes from "prop-types";
import {jwtDecode} from "jwt-decode"; // Import par défaut

function AddFlipCard({ onClose, onAdd }) {
  const navigate = useNavigate();
  const { addFlipCard } = useContext(AppContext);
  const [flipCard, setFlipCard] = useState({
    image_file: null,       // Fichier local
    image_url_web: "",      // URL d'image (si fournie)
    titre: "",
    description: "",
    lien: "",
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [hasRetried, setHasRetried] = useState(false);
  const fileInputRef = useRef(null);

  // Récupération du token et vérification de l'utilisateur au montage
  useEffect(() => {
    const localToken = localStorage.getItem("sessionToken") || sessionStorage.getItem("sessionToken");
    if (!localToken) {
      console.error("Token non disponible");
      navigate("/login");
      return;
    }
    setToken(localToken);
    try {
      const decodedToken = jwtDecode(localToken);
      console.log("Decoded Token:", decodedToken);
      const userIdFromToken = decodedToken.user_id;
      console.log("User ID from token:", userIdFromToken);
      if (!userIdFromToken) {
        alert("⚠️ User ID non disponible !");
        navigate("/login");
        return;
      }
      setUserId(userIdFromToken);
      if (decodedToken.role !== "admin") {
        setErrorMessage("Accès refusé.");
        navigate("/login");
      }
    } catch (error) {
      console.error("Erreur de décodage du token :", error);
      navigate("/login");
    }
  }, [navigate]);

  // Fonction de rafraîchissement du token (à ajuster selon votre backend)
  const refreshToken = useCallback(async () => {
    try {
      const storedRefreshToken = localStorage.getItem("refreshToken");
      const response = await axios.post("https://npm06.com/api/auth", { token: storedRefreshToken });
      const newToken = response.data.token;
      localStorage.setItem("sessionToken", newToken);
      setToken(newToken);
      return newToken;
    } catch (error) {
      console.error("Erreur lors du rafraîchissement du token :", error);
      navigate("/login");
    }
  }, [navigate]);

  const isValidUrl = useCallback((url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }, []);

  // Mise à jour des champs texte (titre, description, lien)
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFlipCard((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  // Mise à jour du champ fichier pour l'image
  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        setError("❌ Format d'image non valide. Formats acceptés : JPG, PNG, GIF.");
        return;
      }
      if (file.size > 2 * 1024 * 1024) {
        setError("❌ L'image ne doit pas dépasser 2MB.");
        return;
      }
      setFlipCard((prevState) => ({ ...prevState, image_file: file }));
      setImagePreview(URL.createObjectURL(file));
      console.log("📸 Image sélectionnée :", file.name);
    }
  }, []);

  const handleImageUrlChange = useCallback((e) => {
    // Permet à l'utilisateur d'entrer une URL pour l'image
    setFlipCard((prevState) => ({ ...prevState, image_url_web: e.target.value }));
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      handleFileChange({ target: { files: [file] } });
    },
    [handleFileChange]
  );

  const handleCancelImage = useCallback(() => {
    setFlipCard((prevState) => ({ ...prevState, image_file: null, image_url_web: "" }));
    setImagePreview(null);
  }, []);

  // Fonction qui réalise la soumission avec le token donné
  const submitFlipCard = useCallback(
    async (currentToken) => {
      let finalImageUrl = null;
      // Priorité à l'URL web si elle est fournie et valide
      if (flipCard.image_url_web && isValidUrl(flipCard.image_url_web)) {
        finalImageUrl = flipCard.image_url_web;
        console.log("Utilisation de l'URL d'image :", finalImageUrl);
      } else if (flipCard.image_file instanceof File) {
        console.log("📤 Upload de l'image locale...");
        const formData = new FormData();
        formData.append("image", flipCard.image_file);
        const uploadResponse = await axios.post("https://npm06.com/uploads", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${currentToken}`,
          },
        });
        finalImageUrl = uploadResponse.data.fileUrl || uploadResponse.data.imageUrl;
        console.log("✅ Image uploadée avec succès :", finalImageUrl);
      }
      const flipCardData = {
        titre: flipCard.titre,
        description: flipCard.description,
        lien: flipCard.lien,
        image_url: finalImageUrl, // Peut être null si aucune image n'est fournie
        userId: userId,
      };
      console.log("📤 Envoi des données de la FlipCard :", flipCardData);
      const response = await axios.post("https://npm06.com/api/flipCard", flipCardData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentToken}`,
        },
      });
      console.log("✅ FlipCard créée :", response.data);
      return response.data;
    },
    [flipCard, userId, isValidUrl]
  );

  // Fonction de soumission globale avec gestion du rafraîchissement et confirmation
  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      if (loading) return; // Empêche un double envoi
      if (!token) {
        setErrorMessage("");
        alert("⚠️ Vous devez être connecté pour ajouter une FlipCard !");
        return;
      }
      if (!flipCard.titre || !flipCard.description) {
        setError("❌ Tous les champs obligatoires doivent être remplis.");
        return;
      }
      if (flipCard.lien && !isValidUrl(flipCard.lien)) {
        setError("❌ Le lien fourni n'est pas valide.");
        return;
      }
      if (!userId) {
        alert("⚠️ User ID non disponible !");
        return;
      }
      setLoading(true);
      setError("");
      try {
        const data = await submitFlipCard(token);
        addFlipCard(data);
        if (onAdd) onAdd(); // Rafraîchit la liste des FlipCards
        setConfirmationMessage("FlipCard créée avec succès !");
        // Réinitialiser les champs
        setFlipCard({ image_file: null, image_url_web: "", titre: "", description: "", lien: "" });
        setImagePreview(null);
        setLoading(false);
        // Afficher la confirmation quelques secondes avant de fermer
        setTimeout(() => {
          setConfirmationMessage("");
          onClose();
        }, 2000);
      } catch (error) {
        if (error.response?.status === 401 && !hasRetried) {
          setHasRetried(true);
          const newToken = await refreshToken();
          if (newToken) {
            try {
              const data = await submitFlipCard(newToken);
              addFlipCard(data);
              if (onAdd) onAdd();
              setConfirmationMessage("FlipCard créée avec succès !");
              setFlipCard({ image_file: null, image_url_web: "", titre: "", description: "", lien: "" });
              setImagePreview(null);
              setLoading(false);
              setTimeout(() => {
                setConfirmationMessage("");
                onClose();
              }, 2000);
              return;
            } catch (retryError) {
              console.error(
                "❌ Erreur lors de la tentative de ré-envoi après refresh :",
                retryError.response?.data || retryError.message
              );
              setError(
                retryError.response?.data?.message ||
                  "❌ Une erreur est survenue lors de la création de la FlipCard."
              );
            }
          }
        } else {
          console.error("❌ Erreur lors de la création :", error.response?.data || error.message);
          setError(error.response?.data?.message || "❌ Une erreur est survenue lors de la création de la FlipCard.");
        }
        setLoading(false);
      }
    },
    [token, flipCard, isValidUrl, addFlipCard, onClose, onAdd, userId, refreshToken, submitFlipCard, hasRetried, loading]
  );

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {errorMessage && <p>{errorMessage}</p>}
        {confirmationMessage && <p className="confirmation-message">{confirmationMessage}</p>}
        {loading && <p>Chargement...</p>}
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <form onSubmit={handleSave}>
          <h2>Ajouter une Flip Card</h2>
          {error && <div className="error-message">{error}</div>}
          <div className="image-upload" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
            {imagePreview ? (
              <>
                <img src={imagePreview} alt="Aperçu" className="image-preview" />
                <button type="button" onClick={handleCancelImage}>
                  Supprimer l'image
                </button>
              </>
            ) : (
              <>
                <input type="file" ref={fileInputRef} onChange={handleFileChange} />
                <p>Glissez une image ou cliquez pour en sélectionner une</p>
              </>
            )}
          </div>
          <div className="input-group">
            <label>Ou bien indiquez l'URL d'une image :</label>
            <input
              type="url"
              name="image_url_web"
              value={flipCard.image_url_web}
              onChange={handleImageUrlChange}
              placeholder="https://exemple.com/image.jpg"
            />
          </div>
          <input type="text" name="titre" value={flipCard.titre} onChange={handleChange} placeholder="Titre" required />
          <textarea name="description" value={flipCard.description} onChange={handleChange} placeholder="Description" required />
          <input type="url" name="lien" value={flipCard.lien} onChange={handleChange} placeholder="Lien (optionnel)" />
          <button type="submit" disabled={loading}>
            {loading ? "Envoi..." : "Ajouter"}
          </button>
        </form>
      </div>
    </div>
  );
}

AddFlipCard.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func, // Pour rafraîchir la liste
};

export default AddFlipCard;
