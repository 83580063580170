import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Breadcrumb from './components/Breadcrumb';
import { useState, useCallback, useEffect, useContext } from 'react';
import { AppContext } from './context/AppContext';
import PropTypes from 'prop-types';
import Header from './components/Header';
import Footer from './components/Footer';
import ServiceOverview from './components/ServiceOverview';
import Signup from './components/Signup';
import Login from './components/Login';
import Profile from './components/Profile';
import MyOrders from './components/MyOrders';
import MonCompte from './components/MonCompte';
import Contact from './components/Contact';
import CartSummary from './components/CartSummary';
import Shopping from './components/Shopping';
import OrderPage from './components/OrderPage';
import Annonces from './components/Annonces';
import AnnonceDetail from './components/AnnoncesDetail';
import ProductDetail from './components/ProductDetail';
import ConditionsGeneralesVente from './components/ConditionsGeneralesVente';
import WebmasterFullStack from './components/WebmasterFullStack';
import CustomSongService from './components/CustomSongService';
import VideoEditing from './components/VideoEditing';
import Evenement from './components/Evenement';
import YouTubeVideos from './components/YouTubeVideos';
import YouTubeDetail from './components/YouTubeDetail';
import PaymentPage from './components/PaymentPage';
import PaymentSuccess from './components/PaymentSuccess';
import ConfirmCommande from './components/ConfirmCommande';
import MessageUser from './components/MessageUser';
import Chatbot from './components/Chatbot';
import SublimationServicePage from './components/SublimationServicePage';
import NotFoundPage from './components/NotFoundPage';
import DashboardLayout from './admin/composants/DashboardLayout';
import ProductManager from './admin/composants/ProductManager';
import SliderManager from './admin/composants/SliderManager';
import AnnoncesManager from './admin/composants/AnnoncesManager';
import UserManager from './admin/composants/UserManager';
import YouTubeManager from './admin/composants/YouTubeManager';
import MessagesManager from './admin/composants/MessagesManager';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import PrivacyPolicyPopup from './components/PrivacyPolicyPopup';
import SliderComponent from './components/SliderComponent';
import Impression3DServicePage from './components/Impression3DServicePage';
import CustomSongPage from './components/CustomSongPage';
import SidebarCat from './components/SidebarCat';
import EventManager from './admin/composants/AdminEventsManager';
import Text3DModal from './Api/Text3DModal'; // Import correct de Text3DModal
import Home from './components/Home';
import Renovation from './components/Rénovation';
import './App.css';

// Hook pour gérer l'authentification
const useAuth = () => {
  const { user } = useContext(AppContext);
  const isAdmin = user?.role === 'admin';
  return { user, isAdmin };
};

// Composant pour les routes protégées (non admin)
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const App = () => {
  const { user, setUser } = useContext(AppContext);

  // État du panier, avec récupération des données stockées en localStorage
  const [cart, setCart] = useState(() => {
    try {
      const storedCart = localStorage.getItem('cart');
      return storedCart ? JSON.parse(storedCart) : [];
    } catch (error) {
      console.error('Erreur de récupération du panier:', error);
      return [];
    }
  });

  const [isPrivacyPopupOpen, setPrivacyPopupOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  // Gestion de la popup des politiques de confidentialité
  useEffect(() => {
    const privacyAccepted = localStorage.getItem('privacyAccepted');
    if (!privacyAccepted) {
      setPrivacyPopupOpen(true);
    }
  }, []);

  const handlePrivacyAccept = () => {
    localStorage.setItem('privacyAccepted', 'true');
    setPrivacyPopupOpen(false);
  };

  const handleLoginSuccess = (userData) => {
    const storage = userData.rememberMe ? localStorage : sessionStorage;
    storage.setItem('user', JSON.stringify(userData));
    setUser(userData); // Mettre à jour l'utilisateur dans le contexte
  };

  const addToCart = useCallback(
    (product) => {
      const existingProduct = cart.find((item) => item.id === product.id);
      let updatedCart;
      if (existingProduct) {
        updatedCart = cart.map((item) =>
          item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        updatedCart = [...cart, { ...product, quantity: 1 }];
      }
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      setCart(updatedCart);
    },
    [cart]
  );

  return (
    <Router future={{ v7_relativeSplatPath: true }}>  {/* Activation du mode v7 */}
      <Header user={user} setUser={setUser} />
     
      { <SliderComponent path="/admin" element={<DashboardLayout />} /> }
      <Breadcrumb />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Home" element={<Home />} />
        <Route path="/ServiceOverview" element={<ServiceOverview />} />
        <Route path="evenement" element={<Evenement />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/login"
          element={
            <Login
              isOpen={isLoginModalOpen}
              onClose={() => setLoginModalOpen(false)}
              onLoginSuccess={handleLoginSuccess}
            />
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cart" element={<CartSummary />} />
        <Route path="/shopping" element={<Shopping addToCart={addToCart} />} />
        <Route path="/orderpage" element={<OrderPage />} />
        <Route path="/annonce" element={<Annonces />} />
        <Route path="/annoncedetail/:id" element={<AnnonceDetail />} />
        <Route path="/productdetail/:id" element={<ProductDetail addToCart={addToCart} />} />
        <Route path="/conditions-generales-vente" element={<ConditionsGeneralesVente />} />
        <Route path="/webmaster-fullstack" element={<WebmasterFullStack />} />
        <Route path="/customsongservice" element={<CustomSongService />} />
        <Route path="/videoediting" element={<VideoEditing />} />
        <Route path="/YouTubeVideos" element={<YouTubeVideos />} />
        <Route path="/youtubedetail/:youtubeId" element={<YouTubeDetail />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/renovation"  element={<Renovation />}  />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/impression3dservice" element={<Impression3DServicePage />} />
        <Route path="/sublimationservicepage" element={<SublimationServicePage />} />
        <Route path="/Sidebarcat" element={<SidebarCat />} />

        {/* Route pour accéder à Text3DModal */}
        <Route path="/text3d" element={<Text3DModal isOpen={true} onClose={() => {}} />} />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              {user && user.user_id ? (
                <Profile userId={user.user_id} updateUser={setUser} />
              ) : (
                <Navigate to="/login" />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/customsongpage"
          element={
            <ProtectedRoute>
              {user && user.user_id ? <CustomSongPage userId={user.user_id} /> : <Navigate to="/login" />}
            </ProtectedRoute>
          }
        />
        <Route path="/MyOrders" element={<ProtectedRoute><MyOrders user={user} /></ProtectedRoute>} />
        <Route path="/confirmcommande" element={<ProtectedRoute><ConfirmCommande userId={user?.user_id} /></ProtectedRoute>} />
        <Route path="/messageuser" element={<ProtectedRoute>{user && user.user_id ? <MessageUser userId={user.user_id} /> : <Navigate to="/login" />}</ProtectedRoute>} />
        <Route path="/MonCompte" element={<ProtectedRoute><MonCompte user={user} setUser={setUser} /></ProtectedRoute>} />

        {/* Routes Admin protégées */}
        <Route path="/admin/dashboardlayout" element={<AdminProtectedRoute user={user}><DashboardLayout /></AdminProtectedRoute>} />
        <Route path="/admin/productmanager" element={<AdminProtectedRoute user={user}><ProductManager /></AdminProtectedRoute>} />
        <Route path="/admin/slider-manager" element={<AdminProtectedRoute user={user}><SliderManager /></AdminProtectedRoute>} />
        <Route path="/admin/messagesmanager" element={<AdminProtectedRoute user={user}><MessagesManager /></AdminProtectedRoute>} />
        <Route path="/admin/usermanager" element={<AdminProtectedRoute user={user}><UserManager /></AdminProtectedRoute>} />
        <Route path="/admin/annoncesmanager" element={<AdminProtectedRoute user={user}><AnnoncesManager /></AdminProtectedRoute>} />
        <Route path="/admin/youtubemanager" element={<AdminProtectedRoute user={user}><YouTubeManager /></AdminProtectedRoute>} />
        <Route path="/admin/eventmanager" element={<AdminProtectedRoute user={user}><EventManager /></AdminProtectedRoute>} />

        {/* Page non trouvée */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <Footer />

      {isPrivacyPopupOpen && (
        <PrivacyPolicyPopup isOpen={isPrivacyPopupOpen} onClose={handlePrivacyAccept} />
      )}
    </Router>
  );
};

export default App;
