import axiosInstance from '../utils/axiosInstance';

export const getFlipCards = async () => {
  try {
    const response = await axiosInstance.get('/flipCard');
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des flip cards:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error.message;
  }
};

export const createFlipCard = async (flipCard) => {
  try {
    const formData = new FormData();
    if (flipCard.image_url) {
      formData.append('image_url', flipCard.image_url);  // Ajouter l'image
    }
    formData.append('titre', flipCard.titre);
    formData.append('description', flipCard.description);
    formData.append('lien', flipCard.lien);

    const response = await axiosInstance.post('/flipCard', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Assurez-vous d'envoyer le type de contenu approprié
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la création de la flip card:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error.message;
  }
};

export const updateFlipCard = async (id, flipCard) => {
  try {
    const response = await axiosInstance.put(`/flipCard/${id}`, flipCard);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la flip card:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error.message;
  }
};

export const deleteFlipCard = async (id) => {
  try {
    await axiosInstance.delete(`/flipCard/${id}`);
  } catch (error) {
    console.error('Erreur lors de la suppression de la flip card:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error.message;
  }
};
