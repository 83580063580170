import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../AdminScss/userManager.scss';

const UserManager = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    usersurname: '',
    email: '',
    role: 'user',
    adresse: '',
    code_postal: '',
    ville: '',
    pays: '',
    phone: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    if (!parsedUser || parsedUser.role !== 'admin' || !token) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      fetchUsers(token);
    }
  }, [navigate]);

  const fetchUsers = async (token) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch('https://www.npm06.com/api/users', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des utilisateurs');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      setErrorMessage('Erreur de récupération des utilisateurs. Veuillez réessayer.');
      console.error('Erreur lors de la récupération des utilisateurs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }, []);
  
  const handleSave = async (e) => {
    e.preventDefault();
    const userData = { ...formData };
    try {
      const token = getToken();
      const url = selectedUser && selectedUser.id
        ? `https://www.npm06.com/api/users/${selectedUser.id}`
        : `https://www.npm06.com/api/users`;

      const method = selectedUser ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        await fetchUsers(token);
        closeModal();
      } else {
        const errorResponse = await response.json();
        setErrorMessage('Une erreur est survenue lors de l\'enregistrement.');
        console.error('Erreur lors de l\'enregistrement de l\'utilisateur:', errorResponse);
      }
    } catch (error) {
      setErrorMessage('Erreur lors de l\'enregistrement. Veuillez réessayer.');
      console.error('Erreur lors de l\'enregistrement de l\'utilisateur:', error);
    }
  };

  const handleDeleteUser = async (id) => {
    const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?');
    if (!confirmation) return;

    try {
      const token = getToken();
      const url = `https://www.npm06.com/api/users/${id}`;

      const response = await fetch(url, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        await fetchUsers(token);
      } else {
        const errorResponse = await response.text();
        setErrorMessage('Une erreur est survenue lors de la suppression de l\'utilisateur.');
        console.error('Erreur lors de la suppression de l\'utilisateur:', errorResponse);
      }
    } catch (error) {
      setErrorMessage('Erreur lors de la suppression. Veuillez réessayer.');
      console.error('Erreur lors de la suppression de l\'utilisateur:', error);
    }
  };

  const openModal = (user = null) => {
    setSelectedUser(user);
    setFormData({
      username: user?.username || '',
      usersurname: user?.usersurname || '',
      email: user?.email || '',
      role: user?.role || 'user',
      adresse: user?.adresse || '',
      code_postal: user?.code_postal || '',
      ville: user?.ville || '',
      pays: user?.pays || '',
      phone: user?.phone || '',
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <div className="user-manager-container">
      <h1>Gestion des Utilisateurs</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {loading && <p>Chargement...</p>}
      <button className="add-user-button" onClick={() => openModal()}>Ajouter un Utilisateur</button>
      <table className="user-table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th>Rôle</th>
            <th>Adresse</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user) => (
              <tr key={user.id}>
                <td>{`${user.username} ${user.usersurname}`}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>{`${user.adresse}, ${user.ville}, ${user.code_postal}, ${user.pays}`}</td>
                <td>
                  <button onClick={() => openModal(user)}>Modifier</button>
                  <button onClick={() => handleDeleteUser(user.id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">Aucun utilisateur disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSave}>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Prénom"
                required
              />
              <input
                type="text"
                name="usersurname"
                value={formData.usersurname}
                onChange={handleInputChange}
                placeholder="Nom"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                required
              />
              <select
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                required
              >
                <option value="user">Utilisateur</option>
                <option value="admin">Administrateur</option>
              </select>
              <input
                type="text"
                name="adresse"
                value={formData.adresse}
                onChange={handleInputChange}
                placeholder="Adresse"
              />
              <input
                type="text"
                name="code_postal"
                value={formData.code_postal}
                onChange={handleInputChange}
                placeholder="Code postal"
              />
              <input
                type="text"
                name="ville"
                value={formData.ville}
                onChange={handleInputChange}
                placeholder="Ville"
              />
              <input
                type="text"
                name="pays"
                value={formData.pays}
                onChange={handleInputChange}
                placeholder="Pays"
              />
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Téléphone"
              />
              <button type="submit">{selectedUser ? 'Mettre à jour' : 'Ajouter'}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManager;
