// loginHelper.js
const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken');
    if (!refreshToken) throw new Error('Session expirée. Veuillez vous reconnecter.');
  
    const response = await fetch(`https://npm06.com/api/users/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: refreshToken }),
    });
  
    if (!response.ok) {
      throw new Error('Impossible de rafraîchir le token. Veuillez vous reconnecter.');
    }
  
    const data = await response.json();
    const storage = localStorage.getItem('refreshToken') ? localStorage : sessionStorage;
    storage.setItem('sessionToken', data.accessToken); // Mise à jour du token dans le stockage
    return data.accessToken;
  };
  
  // Fonction principale de gestion des requêtes
  export const apiFetch = async (url, options = {}) => {
    let sessionToken = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  
    // Ajouter l’`accessToken` au header Authorization
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${sessionToken}`,
    };
  
    // Essayer d'effectuer la requête initiale
    let response = await fetch(url, options);
  
    // Si la requête échoue avec un statut 401 (token expiré)
    if (response.status === 401) {
      try {
        // Rafraîchir le token
        sessionToken = await refreshAccessToken();
  
        // Réessayer la requête avec le nouveau `accessToken`
        options.headers.Authorization = `Bearer ${sessionToken}`;
        response = await fetch(url, options);
      } catch (error) {
        console.error('Erreur lors du rafraîchissement du token:', error);
        throw error; // Réexpédier l’erreur si le rafraîchissement échoue
      }
    }
  
    if (!response.ok) {
      const error = await response.text();
      throw new Error(`Erreur de la requête: ${error}`);
    }
  
    return response.json();
  };
  