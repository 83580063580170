import { useEffect, useState } from "react";
import { getFlipCards, deleteFlipCard } from "../services/flipCardService";
import AddFlipCard from "./AddFlipCard";
import "../AdminScss/FlipcardList.scss";

const FlipCardList = () => {
  const [flipCards, setFlipCards] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    fetchFlipCards();
  }, []);

  // Fonction pour récupérer les FlipCards depuis l'API
  const fetchFlipCards = async () => {
    try {
      const data = await getFlipCards();
      console.log("Données récupérées :", data); // 🔍 Vérifier les données reçues
      setFlipCards(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des flip cards :", error);
    }
  };

  // Fonction pour supprimer une FlipCard
  const handleDelete = async (id) => {
    try {
      await deleteFlipCard(id);
      fetchFlipCards(); // Rafraîchir la liste après suppression
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);
    }
  };

  // Ouvrir/Fermer le popup d'ajout
  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  return (
    <div className="flipcard-list-container">
      <h2>Liste des Flip Cards</h2>
      <button onClick={openPopup} className="add-flipcard-button">
        Ajouter une Flip Card
      </button>

      {/* POPUP d'ajout */}
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <AddFlipCard onClose={closePopup} onAdd={fetchFlipCards} />
          </div>
        </div>
      )}

      {/* TABLEAU des FlipCards */}
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Titre</th>
            <th>Description</th>
            <th>Lien</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {flipCards.length > 0 ? (
            flipCards.map((flipCard) => {
              // Vérification et construction de l'URL de l'image
              const imageUrl = flipCard.image_url
                ? (flipCard.image_url.startsWith("https")
                    ? flipCard.image_url
                    : `https://npm06.com/uploads/${flipCard.image_url}`)
                : "/images/default-image.jpg"; // Image de repli si aucune image n'est définie

              console.log("URL finale de l'image :", imageUrl); // 🛠️ Débogage

              return (
                <tr key={flipCard.id}>
                  <td>
                    <img
                      src={imageUrl}
                      alt={flipCard.titre}
                      style={{ width: "50px", height: "50px", objectFit: "cover" }}
                      onError={(e) => {
                        console.error("Erreur de chargement de l'image :", e.target.src);
                        e.target.src = "/images/default-image.jpg"; // Image par défaut si erreur
                      }}
                    />
                  </td>
                  <td>{flipCard.titre}</td>
                  <td>{flipCard.description}</td>
                  <td>
                    <a href={flipCard.lien} target="_blank" rel="noopener noreferrer">
                      {flipCard.lien}
                    </a>
                  </td>
                  <td>
                    <button onClick={() => handleDelete(flipCard.id)}>Supprimer</button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="5">Aucune Flip Card disponible.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FlipCardList;
