// axiosInstance.js
import axios from 'axios';

// Rafraîchir le token d'accès
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken');
  if (!refreshToken) throw new Error('Session expirée. Veuillez vous reconnecter.');

  const response = await axios.post('https://npm06.com/api/users/refresh-token', { token: refreshToken });

  if (response.status !== 200) {
    throw new Error('Impossible de rafraîchir le token. Veuillez vous reconnecter.');
  }

  const { accessToken } = response.data;
  const storage = localStorage.getItem('refreshToken') ? localStorage : sessionStorage;
  storage.setItem('sessionToken', accessToken); // Met à jour le token dans le stockage
  return accessToken;
};

// Créer une instance Axios
const apiAxios = axios.create({
  baseURL: 'https://npm06.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Intercepteur pour ajouter automatiquement le token et gérer les erreurs 401
apiAxios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Gère les erreurs 401 pour rafraîchir le token
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshAccessToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return apiAxios(originalRequest);
      } catch (refreshError) {
        console.error('Erreur lors du rafraîchissement du token:', refreshError);
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

export default apiAxios;
