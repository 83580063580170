import { lazy, Suspense, useState, useEffect } from 'react';
import '../scss/Home.scss';
import ModalIntro from './ModalIntro'; // Popup d'intro

const FlipCard = lazy(() => import('./FlipCard'));
// Si vous utilisez le slider sur la page Home, décommentez l'import
// const SliderComponent = lazy(() => import('./SliderComponent'));

function Home() {
  const [isModalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    // Ajout dynamique du script de flux
    const script = document.createElement('script');
    script.src = "https://www.notre-planete.info/news/news.js";
    script.async = true;
    script.onload = () => {
      console.log("Script news.js chargé avec succès.");
    };
    script.onerror = () => {
      console.error("Erreur lors du chargement de news.js");
    };
    document.body.appendChild(script);

    // Nettoyage lors du démontage du composant
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="home-container">
      {/* Affichage de la popup d'introduction */}
      {isModalOpen && <ModalIntro onClose={() => setModalOpen(false)} />}

      <h2 className="section-title">Nos Services</h2>
      <section className="home-services">
        <Suspense fallback={<p>Chargement...</p>}>
          <FlipCard 
            frontImage="/web-development.webp"
            title="Développement Web Fullstack"
            description="Création et gestion de sites web performants en React et Node.js, UX/UI optimisé, maintenance serveur."
            link="/webmaster-fullstack"
          />
          <FlipCard 
            frontImage="/audiovisuel.webp"
            title="Production Audiovisuelle"
            description="Films, vidéos, enregistrement sonore et édition musicale pour vos projets professionnels et artistiques."
            link="/videoediting"
          />
          <FlipCard 
            frontImage="/evenementiel.webp"
            title="Événementiel"
            description="Organisation et gestion d'événements, animations, spectacles et logistique pour particuliers et professionnels."
            link="/evenement"
          />
          <FlipCard 
            frontImage="/impression_3D.webp"
            title="Impression 3D"
            description="Prototypage et impression 3D haute précision pour la création d'objets sur mesure et personnalisés."
            link="/impression3dservice"
          />
          <FlipCard 
            frontImage="/sublimation.webp"
            title="Sublimations"
            description="Personnalisation textile et objets grâce aux techniques avancées de sublimation et d'impression UV."
            link="/sublimationservicepage"
          />
          <FlipCard 
            frontImage="/btp.webp"
            title="Travaux de Rénovation"
            description="Rénovation intérieure et extérieure, amélioration de l’habitat et prestations sur-mesure pour vos projets."
            link="/renovation"
          />
        </Suspense>
      </section>

      {/* Conteneur pour le flux du script externe */}
      <div id="news-feed"></div>

      {/* Exemple d'intégration du slider (décommenter si nécessaire) */}
      {/* <Suspense fallback={<p>Chargement du slider...</p>}>
        <SliderComponent />
      </Suspense> */}
    </div>
  );
}

export default Home;
