import { useState } from "react";
import PropTypes from "prop-types";
import FlipCard from "./FlipCard"; // Vérifiez que le chemin est correct
import "../scss/Renovation.css";
import styles from '../scss/Renovation.module.css';

// Import des images depuis le dossier assets
import projet1 from "../assets/robot.png";
import projet2 from "../assets/robot.png";
import projet3 from "../assets/robot.png";

function Renovation() {
  const [isOpen, setIsOpen] = useState(false);

  // Exemple de données pour les flip cards avec les images importées
  const flipCardsData = [
    { 
      frontImage: projet1, 
      title: "Projet 1", 
      description: "Description du projet 1", 
      link: "/projet1" 
    },
    { 
      frontImage: projet2, 
      title: "Projet 2", 
      description: "Description du projet 2", 
      link: "/projet2" 
    },
    { 
      frontImage: projet3, 
      title: "Projet 3", 
      description: "Description du projet 3", 
      link: "/projet3" 
    }
  ];

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
<header className={styles.header}>
  <h1 className={styles.title}>Travaux de Rénovation</h1>
  <p className={styles.subtitle}>Transformez votre intérieur avec nos experts</p>
</header>



      {/* Conteneur spécifique pour Renovation */}
      <div className="renovation">
        {/* Section à deux colonnes */}
        <section className="max-w-6xl mx-auto py-10 px-6">
          <div className="two-column-section">
            {/* Colonne de gauche : Texte de présentation */}
            <div className="presentation-text">
              <h2>Nos Services</h2>
              <p>
                Nous vous accompagnons dans la rénovation de votre habitat, du sol au plafond. Nos équipes vous garantissent un travail de qualité avec des matériaux durables.
              </p>
              <ul>
                <li>🔨 Rénovation intérieure : sols, murs, plafonds</li>
                <li>🏠 Isolation et amélioration énergétique</li>
                <li>🛁 Réaménagement de salle de bain et cuisine</li>
                <li>🎨 Peinture et finitions personnalisées</li>
              </ul>
              <button onClick={() => setIsOpen(true)}>
                Demander un devis
              </button>
            </div>

            {/* Colonne de droite : Flip cards */}
            <div className="flip-cards-container">
              <h2>Nos Travaux</h2>
              <div className="cards-grid">
                {flipCardsData.map((card, index) => (
                  <FlipCard 
                    key={index}
                    frontImage={card.frontImage}
                    title={card.title}
                    description={card.description}
                    link={card.link}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Popup Formulaire */}
      {isOpen && <PopupForm setIsOpen={setIsOpen} />}
    </div>
  );
}

// Composant PopupForm (identique à votre version initiale)
function PopupForm({ setIsOpen }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-red-700 mb-4">Demande de Rénovation</h2>
        <form className="space-y-4">
          <div>
            <label className="block text-gray-700 font-bold">Nom</label>
            <input type="text" className="w-full p-2 border rounded-lg" placeholder="Votre nom" required />
          </div>
          <div>
            <label className="block text-gray-700 font-bold">Email</label>
            <input type="email" className="w-full p-2 border rounded-lg" placeholder="Votre email" required />
          </div>
          <div>
            <label className="block text-gray-700 font-bold">Type de rénovation</label>
            <select className="w-full p-2 border rounded-lg">
              <option>Rénovation intérieure</option>
              <option>Isolation et énergie</option>
              <option>Salle de bain / Cuisine</option>
              <option>Peinture et décoration</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-bold">Message</label>
            <textarea className="w-full p-2 border rounded-lg" rows="3" placeholder="Décrivez votre projet"></textarea>
          </div>
          <button type="submit" className="w-full bg-red-700 text-white p-3 rounded-lg font-bold hover:bg-red-600 transition duration-300">
            Envoyer
          </button>
        </form>
        <button onClick={() => setIsOpen(false)} className="mt-4 w-full text-gray-500 hover:underline text-center">
          Annuler
        </button>
      </div>
    </div>
  );
}

PopupForm.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default Renovation;
