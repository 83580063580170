import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import apiAxios from '..//utils/axiosInstance';
import '../AdminScss/adminSession.scss';

const AdminSession = () => {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    user_id: '',
    session_token: '',
    created_at: '',
    expires_at: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getToken = () => localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');

  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    if (!parsedUser || parsedUser.role !== 'admin' || !token) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      fetchSessions();
    }
  }, [navigate]);

  // Fonction pour récupérer les sessions
// Exemple de requête
const fetchSessions = async () => {
  setLoading(true);
  try {
    const response = await apiAxios.get('/sessions');
    setSessions(response.data);
  } catch (error) {
    console.error('Erreur lors de la récupération des sessions:', error);
    alert('Erreur de récupération des sessions. Veuillez réessayer.');
  } finally {
    setLoading(false);
  }
};

  // Gestion des changements dans le formulaire
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  // Enregistrer ou mettre à jour une session
  const handleSave = async (e) => {
    e.preventDefault();
    const sessionData = { ...formData };
    const token = getToken();
    try {
      const url = selectedSession
        ? `/sessions/${selectedSession.session_id}`
        : `/sessions`;

      const method = selectedSession ? 'put' : 'post';

      await apiAxios(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: sessionData,
      });
      await fetchSessions();
      closeModal();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de la session:", error);
      setErrorMessage("Une erreur est survenue lors de l'enregistrement. Veuillez réessayer.");
    }
  };

  // Supprimer une session
  const handleDeleteSession = async (id) => {
    const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer cette session ?');
    if (!confirmation) return;

    try {
      await apiAxios.delete(`/sessions/${id}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      await fetchSessions();
    } catch (error) {
      console.error("Erreur lors de la suppression de la session:", error);
      setErrorMessage("Une erreur est survenue lors de la suppression de la session.");
    }
  };

  // Ouvrir le modal pour ajouter ou modifier une session
  const openModal = (session = null) => {
    setSelectedSession(session);
    setFormData({
      user_id: session?.user_id || '',
      session_token: session?.session_token || '',
      created_at: session?.created_at || new Date().toISOString().slice(0, 19),
      expires_at: session?.expires_at || new Date(Date.now() + 3600000).toISOString().slice(0, 19),
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSession(null);
  };

  return (
    <div className="admin-session-container">
      <h1>Gestion des Sessions</h1>
      {errorMessage && <p>{errorMessage}</p>}
      {loading && <p>Chargement...</p>}
      <button className="add-session-button" onClick={() => openModal()}>Ajouter une Session</button>
      <table className="session-table">
        <thead>
          <tr>
            <th>Session ID</th>
            <th>User ID</th>
            <th>Session Token</th>
            <th>Créée le</th>
            <th>Expire le</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sessions.length > 0 ? (
            sessions.map((session) => (
              <tr key={session.session_id}>
                <td>{session.session_id}</td>
                <td>{session.user_id || 'Non assigné'}</td>
                <td>{session.session_token}</td>
                <td>{new Date(session.created_at).toLocaleString()}</td>
                <td>{new Date(session.expires_at).toLocaleString()}</td>
                <td>
                  <button onClick={() => openModal(session)}>Modifier</button>
                  <button onClick={() => handleDeleteSession(session.session_id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">Aucune session disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSave}>
              <input
                type="text"
                name="user_id"
                value={formData.user_id}
                onChange={handleInputChange}
                placeholder="User ID"
              />
              <input
                type="text"
                name="session_token"
                value={formData.session_token}
                onChange={handleInputChange}
                placeholder="Session Token"
                required
              />
              <input
                type="datetime-local"
                name="created_at"
                value={formData.created_at}
                onChange={handleInputChange}
                placeholder="Date de création"
                required
              />
              <input
                type="datetime-local"
                name="expires_at"
                value={formData.expires_at}
                onChange={handleInputChange}
                placeholder="Date d'expiration"
                required
              />
              <button type="submit">{selectedSession ? 'Mettre à jour' : 'Ajouter'}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSession;
