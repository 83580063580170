import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../AdminScss/categoryManager.scss';

const CategoryManager = () => {
  const [categories, setCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    subcategories: [''], // Initialisation avec un champ vide
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    if (!parsedUser || parsedUser.role !== 'admin' || !token) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      fetchCategories(token);
    }
  }, [navigate]);

  const fetchCategories = async (token) => {
    setLoading(true);
    try {
      const response = await fetch('https://www.npm06.com/api/categories', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des catégories');
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories:', error);
      alert('Erreur de récupération des catégories. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  const fetchSubcategories = useCallback(async (token, categoryId) => {
    if (!categoryId) return;
    try {
      const response = await fetch(`https://www.npm06.com/api/subcategories/category/${categoryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des sous-catégories');
      const data = await response.json();
      setFormData((prevFormData) => ({
        ...prevFormData,
        subcategories: data || [], // Met à jour formData.subcategories
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des sous-catégories:', error);
    }
  }, []);
  

  useEffect(() => {
    const token = getToken();
    if (selectedCategory && selectedCategory.id) {
      fetchSubcategories(token, selectedCategory.id);
    }
  }, [selectedCategory, fetchSubcategories]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubcategoryChange = (e, index) => {
    const updatedSubcategories = [...formData.subcategories];
    updatedSubcategories[index] = e.target.value;
    setFormData((prevState) => ({ ...prevState, subcategories: updatedSubcategories }));
  };

  const addSubcategoryField = () => {
    setFormData((prevState) => ({
      ...prevState,
      subcategories: [...prevState.subcategories, ''],
    }));
  };

  const removeSubcategoryField = (index) => {
    const updatedSubcategories = [...formData.subcategories];
    updatedSubcategories.splice(index, 1);
    setFormData((prevState) => ({ ...prevState, subcategories: updatedSubcategories }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const categoryData = {
      name: formData.name,
      subcategories: formData.subcategories.map((name) => ({ name })), // Structure correcte
    };

    try {
      const token = getToken();
      const url = selectedCategory && selectedCategory.id
        ? `https://www.npm06.com/api/categories/${selectedCategory.id}`
        : `https://www.npm06.com/api/categories`;

      const method = selectedCategory ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(categoryData),
      });

      if (response.ok) {
        await fetchCategories(token);
        closeModal();
      } else {
        const errorMessage = await response.json();
        console.error('Erreur lors de l\'enregistrement de la catégorie:', errorMessage);
        alert('Une erreur est survenue lors de l\'enregistrement. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la catégorie:', error);
      alert('Une erreur est survenue. Veuillez vérifier votre connexion et réessayer.');
    }
  };

  const handleDeleteCategory = async (id) => {
    const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie ?');
    if (!confirmation) return;

    try {
      const token = getToken();
      const url = `https://www.npm06.com/api/categories/${id}`;

      const response = await fetch(url, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        await fetchCategories(token);
      } else {
        const errorMessage = await response.text();
        console.error('Erreur lors de la suppression de la catégorie:', errorMessage);
        alert('Une erreur est survenue lors de la suppression de la catégorie.');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la catégorie:', error);
      alert('Une erreur est survenue. Veuillez vérifier votre connexion et réessayer.');
    }
  };

  const openModal = (category = null) => {
    setSelectedCategory(category);
    setFormData({
      name: category?.name || '',
      subcategories: category?.subcategories?.map(sub => sub.name) || [''],
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCategory(null);
  };

  return (
    <div className="category-manager-container">
      <h1>Gestion des Catégories</h1>
      {errorMessage && <p>{errorMessage}</p>}
      {loading && <p>Chargement...</p>}
      <button className="add-category-button" onClick={() => openModal()}>Ajouter une Catégorie</button>
      <table className="category-table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Sous-catégories</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.length > 0 ? (
            categories.map((category) => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>{category.subcategories.map(sub => sub.name).join(', ')}</td>
                <td>
                  <button onClick={() => openModal(category)}>Modifier</button>
                  <button onClick={() => handleDeleteCategory(category.id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">Aucune catégorie disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSave}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Nom de la catégorie"
                required
              />
              <div className="subcategory-section">
                <h4>Sous-catégories</h4>
                {formData.subcategories.map((sub, index) => (
                  <div key={index} className="subcategory-input">
                    <input
                      type="text"
                      value={sub}
                      onChange={(e) => handleSubcategoryChange(e, index)}
                      placeholder="Nom de la sous-catégorie"
                    />
                    <button type="button" onClick={() => removeSubcategoryField(index)}>
                      Supprimer
                    </button>
                  </div>
                ))}
                <button type="button" onClick={addSubcategoryField}>
                  Ajouter une sous-catégorie
                </button>
              </div>
              <button type="submit">{selectedCategory ? 'Mettre à jour' : 'Ajouter'}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryManager;
