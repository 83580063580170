import { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import apiAxios from '../admin/utils/axiosInstance';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [activeComponent, setActiveComponent] = useState('ProductManager');
  const [isPrivacyPopupOpen, setPrivacyPopupOpen] = useState(true);
  const [products, setProducts] = useState([]);
  const [annonces, setAnnonces] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  // 🔹 Gestion du panier avec localStorage
  const [cart, setCart] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('cart')) || [];
    } catch (error) {
      console.error("Erreur lors de la récupération du panier :", error);
      return [];
    }
  });

  // 🔹 Gestion de l'utilisateur
  const [user, setUser] = useState(() => {
    try {
      const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
if (storedUser) {
  try {
    const parsedUser = JSON.parse(storedUser);
    if (parsedUser?.user_id) {
      console.log("✅ Utilisateur récupéré du stockage :", parsedUser);
      return parsedUser;
    }
    console.error("⚠️ Utilisateur récupéré sans user_id :", parsedUser);
  } catch (error) {
    console.error("Erreur de parsing du utilisateur dans le stockage :", error);
  }
}
return null;

    } catch (error) {
      console.error("Erreur lors de la récupération de l'utilisateur :", error);
      return null;
    }
  });

  const isLoggedIn = !!user?.user_id;

  // 🔄 Sauvegarde automatique du panier dans localStorage
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // 🔄 Sauvegarde automatique de l'utilisateur dans le bon stockage
  useEffect(() => {
    if (user?.user_id) {
      const storage = user.rememberMe ? localStorage : sessionStorage;
      storage.setItem('user', JSON.stringify(user));
      console.log("✅ Utilisateur sauvegardé dans le stockage :", user);
    } else {
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
      console.log("⚠️ Utilisateur déconnecté, suppression du stockage.");
    }
  }, [user]);

  // 🔹 Connexion et déconnexion utilisateur
  const handleLoginSuccess = useCallback((userData) => {
    if (userData && userData.user_id) {
      setUser(userData);
      const storage = userData.rememberMe ? localStorage : sessionStorage;
      storage.setItem('user', JSON.stringify(userData));
      console.log("✅ Utilisateur connecté :", userData);
    } else {
      console.error('Erreur: les données utilisateur sont invalides.');
    }
  }, []);

  const handleLogout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    console.log("⚠️ Utilisateur déconnecté.");
  }, []);

  // 🔹 Gestion du panier
  const addToCart = useCallback((product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      return existingProduct
        ? prevCart.map((item) => (item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item))
        : [...prevCart, { ...product, quantity: 1 }];
    });
  }, []);

  const removeFromCart = useCallback((productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
  }, []);

  // 🔹 Charger les produits depuis l'API
  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await apiAxios.get('/products');
      setProducts(data);
    } catch (error) {
      console.error("Erreur lors du chargement des produits:", error);
      setError("Impossible de charger les produits.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 🔹 Charger les annonces approuvées (si connecté)
  const fetchAnnonces = useCallback(async () => {
    if (!isLoggedIn) return;
    setIsLoading(true);
    try {
      const url = user?.role === 'admin' ? '/annonces/' : '/annonces/approved';
      const { data } = await apiAxios.get(url);
      setAnnonces(data);
    } catch (error) {
      console.error("Erreur lors du chargement des annonces:", error);
      setError("Impossible de charger les annonces.");
    } finally {
      setIsLoading(false);
    }
  }, [user, isLoggedIn]);
  

  // 🔹 Ajouter une annonce
  const addAnnonce = useCallback(async (formData) => {
    if (!isLoggedIn) return;
    try {
      const { data } = await apiAxios.post('/annonces', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setAnnonces((prevAnnonces) => [...prevAnnonces, data]);
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'annonce:", error);
      setError("Impossible d'ajouter l'annonce.");
    }
  }, [isLoggedIn]);

  // 🔹 Ajouter une FlipCard
  const addFlipCard = useCallback(async (formData) => {
    if (!isLoggedIn) return;
    try {
      const { data } = await apiAxios.post('/flipcards', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setAnnonces((prevFlipCards) => [...prevFlipCards, data]); // Met à jour la liste des FlipCards
    } catch (error) {
      console.error("Erreur lors de l'ajout de la FlipCard:", error);
      setError("Impossible d'ajouter la FlipCard.");
    }
  }, [isLoggedIn]);

  // 🔄 Chargement initial des produits et annonces
  useEffect(() => {
    fetchProducts();
    fetchAnnonces();
  }, [fetchProducts, fetchAnnonces]);
  

  // 🔹 Gestion du fil d'Ariane (réinitialisation des filtres)
  const resetFilters = () => {
    setSelectedCategory(null);
    setSelectedSubcategory(null);
  };

  // 🔹 Valeur du contexte (Correction des dépendances)
  const contextValue = useMemo(() => ({
    annonces,
    setAnnonces,
    fetchAnnonces,
    addAnnonce,
    addFlipCard,
    activeComponent,
    setActiveComponent,
    isPrivacyPopupOpen,
    setPrivacyPopupOpen,
    products,
    fetchProducts,
    cart,
    setCart,
    addToCart,
    removeFromCart,
    user,
    setUser,
    isLoggedIn,
    handleLoginSuccess,
    handleLogout,
    error,
    isLoading,
    selectedCategory,
    setSelectedCategory,
    selectedSubcategory,
    setSelectedSubcategory,
    resetFilters,
  }), [
    annonces,
    fetchAnnonces,
    addAnnonce,
    addFlipCard,
    activeComponent,
    isPrivacyPopupOpen,
    products,
    fetchProducts,
    cart,
    addToCart,
    removeFromCart,
    user,
    isLoggedIn,
    handleLoginSuccess,
    handleLogout,
    error,
    isLoading,
    selectedCategory,
    selectedSubcategory,
  ]);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContext, AppProvider };