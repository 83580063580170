import axios from 'axios';

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken');
  if (!refreshToken) throw new Error('Session expirée. Veuillez vous reconnecter.');

  const response = await axios.post(`https://npm06.com/api/users/refresh-token`, { token: refreshToken });

  if (response.status !== 200) {
    throw new Error('Impossible de rafraîchir le token. Veuillez vous reconnecter.');
  }

  const { accessToken } = response.data;
  const storage = localStorage.getItem('refreshToken') ? localStorage : sessionStorage;
  storage.setItem('sessionToken', accessToken); // Mise à jour du token dans le stockage
  return accessToken;
};

export const apiAxios = async (url, options = {}) => {
  let token = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');

  // Ajout de l'`accessToken` au header Authorization
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios(url, options);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      try {
        // Rafraîchir le token
        token = await refreshAccessToken();

        // Réessayer la requête avec le nouveau `accessToken`
        options.headers.Authorization = `Bearer ${token}`;
        const response = await axios(url, options);
        return response;
      } catch (refreshError) {
        console.error('Erreur lors du rafraîchissement du token:', refreshError);
        throw refreshError;
      }
    }
    throw error;
  }
};
