import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../AdminScss/ProductManager.scss';
import axios from 'axios';

function EventManager() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [replyMessage, setReplyMessage] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);

  // Champs spécifiques de l'événement pour le formulaire
  const [prenom, setPrenom] = useState('');
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [description, setDescription] = useState('');
  const [typeService, setTypeService] = useState('');
  const [dateEvenement, setDateEvenement] = useState('');

  const navigate = useNavigate();

  const getToken = () => localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://www.npm06.com/api/demande_devis/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEvents(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des événements:", error);
      setErrorMessage("Erreur de récupération des événements. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    const sessionToken = getToken();
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;

    if (!parsedUser || parsedUser.role !== 'admin' || !sessionToken) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      setToken(sessionToken);
    }
  }, [navigate]);

  useEffect(() => {
    if (token) fetchEvents();
  }, [token, fetchEvents]);

  const openModal = (event = null) => {
    setSelectedEvent(event);
    setIsModalOpen(true);

    if (event) {
      setNom(event.nom || '');
      setPrenom(event.prenom || '');
      setEmail(event.email || '');
      setTelephone(event.telephone || '');
      setDescription(event.description || '');
      setTypeService(event.type_service || '');
      setDateEvenement(event.date_evenement || '');
      setReplyMessage('');
      setUploadedFile(null);
      setAdditionalFiles([]);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    setReplyMessage('');
    setUploadedFile(null);
    setAdditionalFiles([]);
  };

  const openFileModal = (file) => {
    setSelectedFile(file);
    setIsFileModalOpen(true);
  };

  const closeFileModal = () => {
    setIsFileModalOpen(false);
    setSelectedFile(null);
  };

  const handleDeleteEvent = async (id) => {
    const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cet événement ?");
    if (!confirmation) return;

    try {
      await axios.delete(`https://www.npm06.com/api/demande_devis/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchEvents();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement:", error);
      setErrorMessage("Erreur de suppression. Veuillez vérifier votre connexion et réessayer.");
    }
  };

  const handleFileChange = (e) => {
    setUploadedFile(e.target.files[0]);
  };

  const handleAdditionalFilesChange = (e) => {
    setAdditionalFiles([...e.target.files]);
  };

  const handleReplySubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('nom', nom);
      formData.append('prenom', prenom);
      formData.append('telephone', telephone);
      formData.append('description', description);
      formData.append('type_service', typeService);
      formData.append('date_evenement', dateEvenement);
      formData.append('message', replyMessage);

      if (uploadedFile) {
        formData.append('image', uploadedFile);
      }

      if (additionalFiles && additionalFiles.length > 0) {
        additionalFiles.forEach(file => {
          formData.append('additional_images', file);
        });
      }

      const response = await axios.post('https://www.npm06.com/api/email/admin-event-reply', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        alert("Le devis a été envoyé avec succès.");
        setUploadedFile(null);
        setAdditionalFiles([]);
        closeModal();
      } else {
        alert("Erreur lors de l'envoi du devis. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      alert("Erreur lors de l'envoi du devis. Veuillez vérifier votre connexion et réessayer.");
    }
  };

  return (
    <div className="event-manager-container">
      <h1>Gestion des Événements</h1>
      {errorMessage && <p>{errorMessage}</p>}
      {loading && <p>Chargement...</p>}
      <button onClick={() => openModal()}>Ajouter un Événement</button>

      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Description</th>
            <th>Type de Service</th>
            <th>Date de l'Événement</th>
            <th>Fichier Vidéo</th>
            <th>Fichier Image</th>
            <th>Utilisateur</th> {/* Colonne pour le nom d'utilisateur */}
            <th>Créé le</th>
            <th>Mis à jour le</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.length > 0 ? (
            events.map((event) => (
              <tr key={event.id}>
                <td>{event.id}</td>
                <td>{event.nom}</td>
                <td>{event.prenom}</td>
                <td>{event.email}</td>
                <td>{event.telephone || 'Non disponible'}</td>
                <td>{event.description || 'Non disponible'}</td>
                <td>{event.type_service}</td>
                <td>{!isNaN(Date.parse(event.date_evenement)) ? new Date(event.date_evenement).toLocaleDateString() : 'Non disponible'}</td>
                <td>
                  {event.fichier_video ? (
                    <button onClick={() => openFileModal(event.fichier_video)}>Voir Vidéo</button>
                  ) : (
                    'Aucun'
                  )}
                </td>
                <td>
                  {event.fichier_image ? (
                    <img
                      src={`https://www.npm06.com/uploads/${event.fichier_image}`}
                      alt="Aperçu"
                      style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                      onClick={() => openFileModal(event.fichier_image)}
                    />
                  ) : (
                    'Aucun'
                  )}
                </td>
                <td>
  {event.user ? (
    event.user.username
  ) : (
    'Utilisateur inconnu'
  )}
</td>
                <td>{event.createdAt && !isNaN(Date.parse(event.createdAt )) ? new Date(event.createdAt ).toLocaleString() : 'Non disponible'}</td>
                <td>{event.updatedAt && !isNaN(Date.parse(event.updatedAt)) ? new Date(event.updatedAt).toLocaleString() : 'Non disponible'}</td>
                <td>
                  <button onClick={() => openModal(event)}>Répondre</button>
                  <button onClick={() => handleDeleteEvent(event.id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="14">Aucun événement disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modale de réponse et autres contenus */}
      {isModalOpen && selectedEvent && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h2>Répondre à la demande</h2>
            <form onSubmit={(e) => e.preventDefault()}>
              <textarea
                id="message"
                name="message"
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
                placeholder="Entrez votre message ici..."
                rows="10"
                required
              ></textarea>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
              />
              <input
                type="file"
                id="additional_files"
                name="additional_files"
                multiple
                onChange={handleAdditionalFilesChange}
              />
              <button type="button" onClick={handleReplySubmit}>
                Envoyer
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Modale de visualisation des fichiers */}
      {isFileModalOpen && selectedFile && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeFileModal}>&times;</span>
            {selectedFile.endsWith('.pdf') ? (
              <embed src={`https://www.npm06.com/uploads/${selectedFile}`} type="application/pdf" width="100%" height="500px" />
            ) : selectedFile.endsWith('.mp4') ? (
              <video controls style={{ maxWidth: '100%' }}>
                <source src={`https://www.npm06.com/uploads/${selectedFile}`} type="video/mp4" />
                Votre navigateur ne supporte pas la lecture de vidéo.
              </video>
            ) : (
              <img src={`https://www.npm06.com/uploads/${selectedFile}`} alt="Fichier" style={{ maxWidth: '100%' }} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default EventManager;
