import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../scss/FlipCard.css';

function FlipCard({ 
  frontImage = "/placeholder.webp", 
  title = "Titre manquant", 
  description = "Description non disponible", 
  link = "/" 
}) {
  const [flipped, setFlipped] = useState(false);
  const navigate = useNavigate();

  const handleImageError = (event) => {
    event.target.src = "/placeholder.webp"; // Image de secours
  };

  return (
    <div 
      className={`flip-card ${flipped ? 'flipped' : ''}`} 
      onMouseEnter={() => setFlipped(true)} 
      onMouseLeave={() => setFlipped(false)}
    >
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img 
            src={frontImage} 
            alt={title} 
            loading="lazy" 
            onError={handleImageError} 
          />
          <h3>{title}</h3>
        </div>
        <div className="flip-card-back">
          <div className="flip-card-content">
            <p>{description}</p>
            <button className="flip-card-button" onClick={() => navigate(link)}>
              En savoir plus
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

FlipCard.propTypes = {
  frontImage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string
};

export default FlipCard;
