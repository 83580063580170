import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../AdminScss/adminCommande.scss';

const AdminCommande = () => {
  const [commandes, setCommandes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  const fetchCommandes = useCallback(async (token) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.get('https://npm06.com/api/orders', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCommandes(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorMessage("Aucune commande trouvée pour cet utilisateur.");
      } else {
        setErrorMessage('Erreur de récupération des commandes. Veuillez réessayer.');
        console.error('Erreur lors de la récupération des commandes:', error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    if (!parsedUser || parsedUser.role !== 'admin' || !token) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      fetchCommandes(token);
    }
  }, [navigate, fetchCommandes]);

  return (
    <div className="admin-commande-container">
      <h1>Gestion des Commandes</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {loading && <p>Chargement des commandes...</p>}
      <button className="refresh-button" onClick={() => fetchCommandes(getToken())} aria-label="Rafraîchir la liste des commandes">
        Rafraîchir la liste
      </button>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Utilisateur</th>
            <th>Date de Création</th>
            <th>Date d'Expédition</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {commandes.length > 0 ? (
            commandes.map((commande) => (
              <tr key={commande.order_id}>
                <td>{commande.order_id}</td>
                <td>{commande.user_name}</td>
                <td>{new Date(commande.createdAt).toLocaleDateString()}</td>
                <td>{commande.invoice_date ? new Date(commande.invoice_date).toLocaleDateString() : 'En préparation'}</td>
                <td>{commande.total_price} €</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">Aucune commande disponible</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminCommande;
