import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import '../scss/MessageUser.scss';
import { apiAxios } from './apiHelper';

const MessageUser = ({ userId }) => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reply, setReply] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();

  const fetchMessages = useCallback(async () => {
    if (!userId) {
      setError('Utilisateur non authentifié');
      setLoading(false);
      return;
    }

    try {
      const response = await apiAxios(`https://npm06.com/api/contactmessages/${userId}`, {
        method: 'GET',
      });

      if (response.data && response.data.data) {
        const fetchedMessages = response.data.data.map((msg) => ({
          ...msg,
          responses: typeof msg.responses === 'string' ? JSON.parse(msg.responses) : msg.responses || [],
        }));

        setMessages(fetchedMessages);
        const savedMessageId = localStorage.getItem('selectedMessageId');
        if (savedMessageId) {
          const foundMessage = fetchedMessages.find(
            (msg) => msg.message_id === parseInt(savedMessageId, 10)
          );
          if (foundMessage) setSelectedMessage(foundMessage);
        }
      } else {
        setMessages([]);
      }
    } catch (err) {
      setError('Erreur lors de la récupération des messages');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) fetchMessages();
  }, [userId, fetchMessages]);

  const handleSelectMessage = useCallback((message) => {
    setSelectedMessage(message);
    localStorage.setItem('selectedMessageId', message.message_id);
  }, []);

  const handleReplyChange = useCallback((e) => {
    setReply(e.target.value);
  }, []);

  const handleSendReply = useCallback(async () => {
    setSending(true);
    if (!userId || !selectedMessage || reply.trim() === '') {
      setError('Utilisateur non authentifié');
      setSending(false);
      return;
    }

    try {
      const newResponse = {
        response_text: reply.trim(),
        response_user_id: userId,
        response_created_at: new Date().toISOString(),
      };

      const updatedResponses = Array.isArray(selectedMessage.responses)
        ? [...selectedMessage.responses, newResponse]
        : [newResponse];

      const response = await apiAxios(
        `https://npm06.com/api/contactmessages/${selectedMessage.message_id}/response`,
        {
          method: 'POST',
          data: newResponse,
        }
      );

      if (response.status === 200) {
        const updatedMessage = {
          ...selectedMessage,
          responses: updatedResponses,
        };

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.message_id === selectedMessage.message_id ? updatedMessage : msg
          )
        );

        setSelectedMessage(updatedMessage);
        setReply(''); // Réinitialiser la réponse après envoi
      } else {
        setError('Erreur lors de l\'envoi de la réponse');
      }
    } catch (err) {
      setError('Erreur lors de l\'envoi de la réponse');
    } finally {
      setSending(false);
    }
  }, [selectedMessage, reply, userId]);

  const handleNewMessage = useCallback(() => {
    navigate('/contact');
  }, [navigate]);

  if (!userId) {
    return <div className="error-message">Erreur : ID utilisateur manquant.</div>;
  }

  if (loading) {
    return <div>Chargement des messages...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="message-user">
      <div className="message-box">
        <div className="sidebar">
          <div className="sidebar-header">
            <h2>Conversations</h2>
            <button onClick={handleNewMessage} className="new-message-button">
              Nouveau Message
            </button>
          </div>
          <ul className="message-list">
            {messages.length > 0 ? (
              messages.map((message) => (
                <li
                  key={message.message_id}
                  className={`subject-item ${selectedMessage?.message_id === message.message_id ? 'active' : ''}`}
                  onClick={() => handleSelectMessage(message)}
                >
                  <h3>{message.subject || 'Pas de sujet'}</h3>
                  <p className="message-preview">
                    {message.message_text
                      ? message.message_text.length > 30
                        ? `${message.message_text.substring(0, 30)}...`
                        : message.message_text
                      : 'Pas de message'}
                  </p>
                </li>
              ))
            ) : (
              <li className="no-messages">Aucun message trouvé.</li>
            )}
          </ul>
        </div>

        <div className="chat-container">
          {selectedMessage ? (
            <>
              <div className="chat-header">
                <h2>{selectedMessage.subject || 'Pas de sujet'}</h2>
              </div>
              <div className="chat-messages">
                {/* Affichage des messages */}
              </div>
              <div className="chat-input">
                <textarea
                  value={reply}
                  onChange={handleReplyChange}
                  placeholder="Votre réponse..."
                  rows={3}
                />
                <button onClick={handleSendReply} disabled={reply.trim() === '' || sending}>
                  {sending ? 'Envoi en cours...' : 'Envoyer'}
                </button>
              </div>
            </>
          ) : (
            <div className="welcome-message">
              <h2>Bienvenue dans votre messagerie</h2>
              <p>Sélectionnez une conversation ou créez un nouveau message pour commencer.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MessageUser.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default MessageUser;
