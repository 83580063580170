// Sidebar.jsx
import '../AdminScss/Navigation.scss';
import '../AdminScss/AdminDashboard.scss';
import PropTypes from 'prop-types';
import { 
  FaBox, FaBullhorn, FaUser, FaShoppingCart, FaEnvelope, 
  FaFileAlt, FaImages, FaVideo, FaUsers 
} from 'react-icons/fa';

const Sidebar = ({ setActiveComponent }) => {
  return (
    <aside className="sidebar">
      <nav>
        <ul>
          {/* Gestion des produits */}
          <li>
            <button onClick={() => setActiveComponent('ProductManager')} aria-label="Gestion des Produits">
              <FaBox /> Produits
            </button>
          </li>

          {/* Gestion des annonces */}
          <li>
            <button onClick={() => setActiveComponent('AnnoncesManager')} aria-label="Gestion des Annonces">
              <FaBullhorn /> Annonces
            </button>
          </li>

          {/* Gestion des événements */}
          <li>
            <button onClick={() => setActiveComponent('EventManager')} aria-label="Gestion des Événements">
              <FaFileAlt /> Événements
            </button>
          </li>

          {/* Gestion des utilisateurs */}
          <li>
            <button onClick={() => setActiveComponent('UserManager')} aria-label="Gestion des Utilisateurs">
              <FaUser /> Utilisateurs
            </button>
          </li>

          {/* Gestion des commandes */}
          <li>
            <button onClick={() => setActiveComponent('AdminCommande')} aria-label="Gestion des Commandes">
              <FaShoppingCart /> Commandes
            </button>
          </li>

          {/* Messages de contact */}
          <li>
            <button onClick={() => setActiveComponent('messageusers')} aria-label="Messages de Contact">
              <FaEnvelope /> Messages de Contact
            </button>
          </li>

          {/* Gestion des catégories */}
          <li>
            <button onClick={() => setActiveComponent('CategoryManager')} aria-label="Gestion des Catégories">
              <FaFileAlt /> Catégories
            </button>
          </li>

          {/* Gestion des chansons */}
          <li>
            <button onClick={() => setActiveComponent('SongManager')} aria-label="Gestion des Chansons">
              <FaImages /> Chansons
            </button>
          </li>

          {/* Gestion des vidéos */}
          <li>
            <button onClick={() => setActiveComponent('YouTubeManager')} aria-label="Gestion des Vidéos">
              <FaVideo /> Vidéos
            </button>
          </li>

          {/* Sessions utilisateurs */}
          <li>
            <button onClick={() => setActiveComponent('AdminSession')} aria-label="Gestion des Sessions Utilisateurs">
              <FaUsers /> Sessions
            </button>
          </li>

          {/* Gestion du slider */}
          <li>
            <button onClick={() => setActiveComponent('SliderManager')} aria-label="Gestion du Slider">
              <FaImages /> Slider
            </button>
          </li>

          {/* Autres messages */}
          <li>
            <button onClick={() => setActiveComponent('MessagesManager')} aria-label="Gestion des Messages">
              <FaEnvelope /> Messages
            </button>
          </li>

          {/* Gestion des FlipCards */}
          <li>
            <button onClick={() => setActiveComponent('FlipCardList')} aria-label="Gestion des FlipCards">
              <FaImages /> FlipCards
            </button>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

Sidebar.propTypes = {
  setActiveComponent: PropTypes.func.isRequired,
};

export default Sidebar;
