// DashboardLayout.jsx
import { useState, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext'; // Assurez-vous que le contexte est correctement configuré
import DashboardHeader from './DashboardHeader';
import DashboardFooter from './DashboardFooter';
import Sidebar from './Sidebar';
import AdminDashboard from './AdminDashboard';
import AdminCommande from './AdminCommande';
import ProductManager from './ProductManager';
import AnnoncesManager from './AnnoncesManager';
import MessagesManager from './MessagesManager';
import SliderManager from './SliderManager';
import UserManager from './UserManager';
import YouTubeManager from './YouTubeManager';
import SongManager from './SongManager';
import CategoryManager from './CategoryManager';
import EventManager from './AdminEventsManager';
import AdminSession from './AdminSession';
import FlipCardList from './FlipCardList';
import AddFlipCard from './AddFlipCard';
import '../AdminScss/DashboardLayout.scss';

const DashboardLayout = () => {
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const { user } = useContext(AppContext); // Récupération de l'utilisateur depuis le contexte

  // Vérification de l'existence de l'utilisateur et de son rôle
  useEffect(() => {
    if (!user) {
      // Redirection vers la page de connexion si l'utilisateur n'est pas défini
      return <Navigate to="/login" />;
    }
  }, [user]);

  // Fonction pour déterminer si l'utilisateur a le rôle d'administrateur
  const isAdmin = user?.role === 'admin';

  // Liste des composants accessibles pour l'administrateur
  const adminComponents = [
    'ProductManager',
    'SliderManager',
    'AnnoncesManager',
    'EventManager',
    'AdminCommande',
    'MessagesManager',
    'UserManager',
    'YouTubeManager',
    'CategoryManager',
    'AdminSession',
    'SongManager',
    'FlipCardList',
    'AddFlipCard',
  ];

  // Fonction pour afficher le composant actif en fonction de la sélection dans la sidebar
  const renderComponent = () => {
    switch (activeComponent) {
      case 'ProductManager':
        return <ProductManager />;
      case 'SliderManager':
        return <SliderManager />;
      case 'AnnoncesManager':
        return <AnnoncesManager />;
      case 'EventManager':
        return <EventManager />;
      case 'AdminCommande':
        return <AdminCommande />;
      case 'MessagesManager':
        return <MessagesManager />;
      case 'UserManager':
        return <UserManager />;
      case 'YouTubeManager':
        return <YouTubeManager />;
      case 'CategoryManager':
        return <CategoryManager />;
      case 'AdminSession':
        return <AdminSession />;
      case 'SongManager':
        return <SongManager />;
      case 'FlipCardList':
        return <FlipCardList />;
      case 'AddFlipCard':
        return <AddFlipCard />;
      default:
        return <AdminDashboard />;
    }
  };

  // Rendu du layout avec la sidebar, le composant actif et le footer
  return (
    <main className="Dash">
      <DashboardHeader />
      <div className="dashboard-layout">
        <Sidebar
          setActiveComponent={setActiveComponent}
          adminComponents={adminComponents}
          isAdmin={isAdmin}
        />
        <div className="content-area">
          {renderComponent()}
        </div>
      </div>
      <DashboardFooter />
    </main>
  );
};

export default DashboardLayout;
