import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import apiAxios from '../utils/axiosInstance';
import '../AdminScss/productManager.scss';

function ProductManager() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    add_details: '',
    price: '',
    image_url: 'no-pict.jpg',
    additional_images: [],
    category_id: '',
    subcategory_id: '',
  });
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isAddingSubcategory, setIsAddingSubcategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newSubcategoryName, setNewSubcategoryName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Vérification de l'authentification et du rôle admin
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user') || '{}');
    if (storedUser?.role !== 'admin') {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    }
  }, [navigate]);

  // Récupération des catégories
  const fetchCategories = useCallback(async () => {
    try {
      const response = await apiAxios.get('/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories:', error);
    }
  }, []);

  // Récupération des produits
  const fetchProducts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiAxios.get('/products');
      const productsData = response.data;

      const updatedProducts = productsData.map((product) => {
        const category = categories.find((cat) => cat.id === product.category_id);
        const subcategory = category ? category.subcategories.find((sub) => sub.id === product.subcategory_id) : null;
        return {
          ...product,
          category_name: category ? category.name : 'Non défini',
          subcategory_name: subcategory ? subcategory.name : 'Non défini',
        };
      });

      setProducts(updatedProducts);
    } catch (error) {
      console.error('Erreur lors de la récupération des produits:', error);
    } finally {
      setLoading(false);
    }
  }, [categories]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (categories.length > 0) {
      fetchProducts();
    }
  }, [categories, fetchProducts]);

  // Gestion des champs du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'category_id') {
      const selectedCategory = categories.find((cat) => cat.id === parseInt(value));
      setSubcategories(selectedCategory ? selectedCategory.subcategories : []);
    }
  };

  // Enregistrer une nouvelle catégorie
  const handleNewCategorySubmit = async () => {
    try {
      const response = await apiAxios.post('/categories', { name: newCategoryName });
      setCategories((prevCategories) => [...prevCategories, response.data]);
      setFormData((prevData) => ({ ...prevData, category_id: response.data.id }));
      setIsAddingCategory(false);
      setNewCategoryName('');
    } catch (error) {
      console.error('Erreur lors de la création de la nouvelle catégorie:', error);
    }
  };

  // Enregistrer une nouvelle sous-catégorie
  const handleNewSubcategorySubmit = async () => {
    try {
      const response = await apiAxios.post('/subcategories', { name: newSubcategoryName, category_id: formData.category_id });
      setSubcategories((prevSubcategories) => [...prevSubcategories, response.data]);
      setFormData((prevData) => ({ ...prevData, subcategory_id: response.data.id }));
      setIsAddingSubcategory(false);
      setNewSubcategoryName('');
    } catch (error) {
      console.error('Erreur lors de la création de la nouvelle sous-catégorie:', error);
    }
  };

  // Gestion de l'upload des images
  const handleFileChange = (e, index = null) => {
    const file = e.target.files[0];
    if (file) {
      if (index === null) {
        setFormData((prevState) => ({ ...prevState, image_url: file }));
        setPreviewImage(URL.createObjectURL(file));
      } else {
        const updatedImages = [...formData.additional_images];
        updatedImages[index] = { type: 'file', value: file, preview: URL.createObjectURL(file) };
        setFormData((prevState) => ({ ...prevState, additional_images: updatedImages }));
      }
    }
  };

  const handleCancelImage = () => {
    setFormData((prevState) => ({ ...prevState, image_url: 'no-pict.jpg' }));
    setPreviewImage(null);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name || '');
    formDataToSend.append('description', formData.description || '');
    formDataToSend.append('add_details', formData.add_details || '');
    formDataToSend.append('price', formData.price || '');
    formDataToSend.append('category_id', formData.category_id || '');
    formDataToSend.append('subcategory_id', formData.subcategory_id || '');
  
    // Vérification de l'image principale (fichier ou URL)
    if (formData.image_url) {
      if (formData.image_url instanceof File) {
        console.log("Ajout du fichier image:", formData.image_url.name);
        formDataToSend.append('image', formData.image_url); // Ajoute le fichier
      } else {
        console.log("Ajout de l'URL de l'image:", formData.image_url);
        formDataToSend.append('image_url', formData.image_url); // Ajoute l’URL
      }
    }
  
    // Vérification et ajout des images supplémentaires
    if (Array.isArray(formData.additional_images)) {
      formData.additional_images.forEach((image, index) => {
        if (image?.value instanceof File) {
          console.log(`Ajout de l'image supplémentaire ${index}:`, image.value.name);
          formDataToSend.append(`additional_images_${index}`, image.value);
        }
      });
    }
  
    // Vérification avant l'envoi
    console.log("Données envoyées:", Object.fromEntries(formDataToSend.entries()));
  
    try {
      const url = selectedProduct ? `/products/${selectedProduct.id}` : '/products';
      const method = selectedProduct ? 'PUT' : 'POST';
  
      await apiAxios(url, {
        method,
        data: formDataToSend,
        headers: { 'Content-Type': 'multipart/form-data' } // Optionnel avec Axios
      });
  
      fetchProducts();
      closeModal();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du produit:", error);
      alert("Une erreur est survenue lors de l'enregistrement. Veuillez réessayer.");
    }
  };
  

  // Supprimer un produit
  const handleDeleteProduct = async (id) => {
    const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer ce produit ?');
    if (!confirmation) return;

    try {
      await apiAxios.delete(`/products/${id}`);
      fetchProducts();
    } catch (error) {
      console.error("Erreur lors de la suppression du produit:", error);
      alert("Une erreur est survenue lors de la suppression du produit.");
    }
  };

  // Ouvrir et fermer la modal de gestion des produits
  const openModal = (product = null) => {
    setSelectedProduct(product);
    setFormData({
      name: product?.name || '',
      description: product?.description || '',
      add_details: product?.add_details || '',
      price: product?.price || '',
      image_url: product?.image_url || 'no-pict.jpg',
      additional_images: Array.isArray(product?.additional_images) ? product.additional_images : [],
      category_id: product?.category_id || '',
      subcategory_id: product?.subcategory_id || '',
    });

    setPreviewImage(
      product?.image_url && product.image_url.startsWith('https')
        ? product.image_url
        : product?.image_url
        ? `https://npm06.com/uploads/${product.image_url}`
        : 'no-pict.jpg'
    );

    if (product?.category_id) {
      const selectedCategory = categories.find((cat) => cat.id === product.category_id);
      setSubcategories(selectedCategory ? selectedCategory.subcategories : []);
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
    setPreviewImage(null);
    setIsAddingCategory(false);
    setIsAddingSubcategory(false);
    setNewCategoryName('');
    setNewSubcategoryName('');
  };

  return (
    <div className="product-manager-container">
      <h1>Gestion des Produits</h1>
      {errorMessage && <p>{errorMessage}</p>}
      {loading && <p>Chargement...</p>}
      <button onClick={() => openModal()}>Ajouter un Produit</button>
      <table className="table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Nom</th>
            <th>Catégorie</th>
            <th>Sous-catégorie</th>
            <th>Description</th>
            <th>Détails supplémentaires</th>
            <th>Prix</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 ? (
            products.map((product) => (
              <tr key={product.id}>
                <td>
                  <img
                    src={product.image_url.startsWith('https') ? product.image_url : `https://npm06.com/uploads/${product.image_url}`}
                    alt={product.name}
                    style={{ width: '50px', height: '50px' }}
                  />
                </td>
                <td>{product.name}</td>
                <td>{product.category_name}</td>
                <td>{product.subcategory_name}</td>
                <td>{product.description}</td>
                <td>{product.add_details}</td>
                <td>{product.price}</td>
                <td>
                  <button onClick={() => openModal(product)}>Modifier</button>
                  <button onClick={() => handleDeleteProduct(product.id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">Aucun produit disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSave}>
              <input type="text" name="name" value={formData.name} onChange={handleInputChange} placeholder="Nom du produit" required />
              <textarea name="description" value={formData.description} onChange={handleInputChange} placeholder="Description" />
              <textarea name="add_details" value={formData.add_details} onChange={handleInputChange} placeholder="Détails supplémentaires" />
              <input type="number" name="price" value={formData.price} onChange={handleInputChange} placeholder="Prix" required />

              <div>
                <label>Catégorie</label>
                {isAddingCategory ? (
                  <div>
                    <input type="text" value={newCategoryName} onChange={(e) => setNewCategoryName(e.target.value)} placeholder="Nouvelle catégorie" />
                    <button type="button" onClick={handleNewCategorySubmit}>Ajouter Catégorie</button>
                  </div>
                ) : (
                  <div>
                    <select name="category_id" value={formData.category_id} onChange={handleInputChange} required>
                      <option value="">Sélectionner une catégorie</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                      ))}
                    </select>
                    <button type="button" onClick={() => setIsAddingCategory(true)}>+ Nouvelle Catégorie</button>
                  </div>
                )}
              </div>

              <div>
                <label>Sous-catégorie</label>
                {isAddingSubcategory ? (
                  <div>
                    <input type="text" value={newSubcategoryName} onChange={(e) => setNewSubcategoryName(e.target.value)} placeholder="Nouvelle sous-catégorie" />
                    <button type="button" onClick={handleNewSubcategorySubmit}>Ajouter Sous-catégorie</button>
                  </div>
                ) : (
                  <div>
                    <select name="subcategory_id" value={formData.subcategory_id} onChange={handleInputChange}>
                      <option value="">Sélectionner une sous-catégorie</option>
                      {subcategories.map((subcategory) => (
                        <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                      ))}
                    </select>
                    <button type="button" onClick={() => setIsAddingSubcategory(true)}>+ Nouvelle Sous-catégorie</button>
                  </div>
                )}
              </div>

              <div>
                <label>Image principale</label>
                <input type="file" name="image_url" onChange={(e) => handleFileChange(e)} />
                {previewImage && (
                  <div className="image-preview">
                    <img src={previewImage} alt="Prévisualisation" style={{ width: '100px', height: '100px' }} />
                    <button type="button" onClick={handleCancelImage}>Annuler l'image</button>
                  </div>
                )}
              </div>

              <button type="submit">{selectedProduct ? 'Mettre à jour' : 'Ajouter'}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductManager;
