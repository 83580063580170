import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './context/Store_redux/store';
import App from './App.jsx';
import{ AppProvider}from "./context/AppContext";

// Sécurisation de l'accès à l'élément root
const container = document.getElementById('root');
if (!container) {
  throw new Error("Élément #root introuvable. Vérifiez votre index.html.");
}

const root = createRoot(container);

root.render(
  <StrictMode>
    <Provider store={store}>
      <AppProvider>
        {/* Ajout de Suspense pour préparer le support d'un rendu asynchrone */}
        <Suspense fallback={<div>Chargement...</div>}>
          <App />
        </Suspense>
      </AppProvider>
    </Provider>
  </StrictMode>
);
