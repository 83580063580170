// Breadcrumb.jsx
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

const Breadcrumb = () => {
  const { selectedCategory, setSelectedCategory, selectedSubcategory, setSelectedSubcategory, resetFilters } = useContext(AppContext);

  const handleShowAllProducts = () => {
    resetFilters();
  };

  return (
    <div className="breadcrumb">
      <Link to="/" onClick={handleShowAllProducts}>Accueil</Link> {' > '}
      <Link to="/shopping" onClick={handleShowAllProducts}>Shopping</Link>
      {selectedCategory && (
        <>
          {' > '}
          <span onClick={() => {
            setSelectedSubcategory(null);
            handleShowAllProducts();
          }} style={{ cursor: 'pointer' }}>
            {selectedCategory.name}
          </span>
        </>
      )}
      {selectedSubcategory && (
        <>
          {' > '}
          <span>{selectedSubcategory.name}</span>
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
