import { useState, useEffect } from 'react';
import '../scss/Modal.scss';

function ModalIntro({ onClose }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Affiche la popup après 2 secondes au chargement de Home
    const timer = setTimeout(() => setShow(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>🌟 Un collectif d’experts pour une offre diversifiée 🌟</h2>
        <p>
          Chez <strong>NPM</strong>, nous avons réuni des passionnés et des experts issus de différents domaines afin 
          de vous offrir une gamme de services variés et complémentaires. 
        </p>
        <p>
          Du <strong>développement web</strong> à la <strong>production audiovisuelle</strong>, en passant par 
          <strong> l’événementiel, l’impression 3D et la sublimation textile</strong>, chaque activité est portée 
          par des spécialistes engagés, prêts à répondre à vos besoins.
        </p>
        <p>
          💡 <strong>Découvrez l’étendue de nos services et trouvez celui qui correspond à vos attentes !</strong>
        </p>
        <button className="modal-close" onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
}

export default ModalIntro;
